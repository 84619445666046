div.section {
	&.welcome {
		text-align: center;

		header {
			margin-bottom: 50px;

			h1.site-title {
				text-transform: uppercase;
				font-size: 4rem;
				margin: 0;
				padding: 0;

				img {
					max-width: 100%;
					height: auto;
				}
			}
		}

		nav {
			display: none;
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				li {
					display: inline;

					@include grid-media($mobile) {
						display:  block;
						width: 100%;
						margin: 0 0 3px;
					}

					a {
						background-color: rgba( $white, .6 );
						color: $black;
						font-family: $metadata;
						text-transform: uppercase;
						text-decoration: none;
						padding: 10px 20px;

						@include grid-media($mobile) {
							background-color: $white;
							display: block;
						}

						&:hover {
							background-color: $white;
						}
					}
				}
			}
		}
	}
}
