div.section {
	div.inner {
		@include grid-media($desktop) {
			padding: 100px 0;
		}

		header.section-header {
			text-align: center;

			h2.section-title {
				background-color: $white;
				color: $black;
				font-family: $metadata;
				font-weight: 400;
				text-transform: uppercase;
				letter-spacing: 2px;
				display: inline-block;
				padding: 3px 10px 4px;
			}
		}
		ol {

			@include grid-media($desktop) {
				columns: auto 2;
			}

			li {
				font-size: 1.1rem;
				line-height: 1.5rem;
				margin: 0 2rem 1rem 0;
			}
		}
	}
}
