@import url('https://fonts.googleapis.com/css?family=Dosis:400,700|PT+Serif:400,400i,700,700i');

@import '../vendors/normalize-scss/sass/normalize';
@import '../vendors/neat/core/neat';
@import '../vendors/fullpage.js/dist/fullpage.min.css';

@import 'partials/colors';
@import 'partials/typography';
@import 'partials/grid';
@import 'partials/navigation';

@import 'design/general';

@import 'sections/header';
@import 'sections/footer';
@import 'sections/panels';
