$mobile: (
	columns: 4,
	gutter: 20px,
	media: 300px
);
$desktop: (
	columns: 12,
	gutter: 20px,
	media: "(min-width: 768px)",
);

body {
	div#fullpage {
		div.section {
			@include grid-container;

			div.inner {
				@include grid-column(4, $mobile);

				@include grid-media($desktop) {
					@include grid-column(8, $desktop);
					@include grid-push(2, $desktop);
				}
			}

			&.credits {
				div.inner {
					@include grid-column(4, $mobile);

					@include grid-media($desktop) {
						@include grid-column(2, $desktop);
						@include grid-push(5, $desktop);
					}
				}
			}
		}
	}
}
