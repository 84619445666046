body {
	background: url('../images/dark.jpg') center center no-repeat $black;
	background-size: cover;
	color: $white;

	a {
		transition: linear .5s all;
		font-weight: bold;
		text-decoration: none;
		color: $white;

		&:hover {
			text-decoration: underline;
		}
	}

	div.section {
		&.welcome {
			//background: url('../images/splash.jpg') center center no-repeat;
			//background-size: cover;
		}
	}
}
